import { useEffect } from 'react'
import './style.scss'

const HomePage = ({toIntro,audioRef})=>{
    useEffect(()=>{
        audioRef.current.audioEl.current.play();
    },[])
    return  <>
    <div className='container ball pc'>
        <div className='ball-wrap'  onClick={(e)=>{
            console.log(e.target.className)
                if(e.target.className === 'ball'){
                    +e.target.dataset.idx && toIntro(e.target.dataset.idx)
                }
             }}>
        <div className='ball' data-idx='1'></div>
        <div className='ball' data-idx='2'></div>
        <div className='ball' data-idx='3'></div>
        <div className='ball' data-idx='4'></div>
        <div className='ball ball-5' data-idx='0'></div>
        </div>
     
    </div>
    <div className='container ball wap'>
        <div className='ball-wrap'  onClick={(e)=>{
            console.log(e.target.className)
                if(e.target.className === 'ball'){
                    +e.target.dataset.idx && toIntro(e.target.dataset.idx)
                }
             }}>
        <div className='ball' data-idx='1'></div>
        <div className='ball' data-idx='2'></div>
        <div className='ball' data-idx='3'></div>
        <div className='ball' data-idx='4'></div>
        <div className='ball ball-5' data-idx='0'></div>
        </div>
     
    </div>
    </>
}
export default HomePage;