import "./style.scss";
import under from "../../images/under.png";
import p1 from "../../images/p1.png";
import p2 from "../../images/p2.png";
import p3 from "../../images/p3.png";
import bgLogo from "../../images/logo/logo-bg.png";
import longArrow from "../../images/icon/arrow-left.png";
import specArrow from "../../images/icon/arrow-left-right.png";
import logo_s from "../../images/icon/small-logo.png";
import star from "../../images/star.png";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import {ReactCSSTransitionGroup} from 'react-transition-group';
import SmokeText from '../../components/SmokeText/index';
import "animate.css";

const IntroPage = ({pageIndex,toHome,audioRef}) => {
  const [closed, setClosed] = useState(false);
  const [showIdx, setShowId] = useState(0);
  const idx = useRef(0);

  useEffect(()=>{
    setShowId(pageIndex -1);
    idx.current = pageIndex -1;
  },[pageIndex])


  useEffect(()=>{
    console.log(audioRef,closed)
    const audio =  audioRef.current&&audioRef.current.audioEl.current
    if(!audio) return
    if(closed){
      // audioRef.current.pause() //停止（暂停）
      
      audioRef.current.audioEl.current.pause()
    }else{
      // audioRef.current.audioRef.current.load()
      audioRef.current.audioEl.current.play()
    }

  },[closed,audioRef])



  return (
    <div className={classNames("whole-page", showIdx)}>
      <div className="lef-nav-box">
        <div className="nav-item" onClick={toHome}></div>
        <div
          className="nav-item"
          onClick={() => {
            const w =window.open('about:blank');
            w.location.href="https://twitter.com/RebelsDAO"
          }}
        ></div>
        <div
          className={classNames("nav-item", { closed: closed })}
          onClick={() => {
            setClosed(!closed);
          }}
        ></div>
      </div>
      <div className="right-nav-box">
        <div
          className="nav-item"
          onClick={() => {
            console.warn(idx.current);

            if (idx.current === 0) {
              setShowId(3);
              idx.current = 3;
              return;
            }
            idx.current--;
            setShowId(idx.current);
          }}
        ></div>
        <div
          className="nav-item"
          onClick={() => {
            console.warn(idx.current);

            if (idx.current === 3) {
              setShowId(0);
              idx.current = 0;
              return;
            }
            idx.current++;

            setShowId(idx.current);
          }}
        ></div>
      </div>
      <div className="content-item">
        <div className="main-view">
          {showIdx === 0 && <OnePage />}
          {showIdx === 1 && <TwoPage />}
          {showIdx === 2 && <ThreePage />}
          {showIdx === 3 && <FourPage />}
        </div>
      </div>
    </div>
  );
};

const OnePage = () => {
  return (
    <div className="item-one">
      <img className="logo-icon-small" src={logo_s} />
      <div className="wrap">
        <img src={bgLogo} className="bg" />
        <div className="flex">

          <span className="so scratchy">So</span>
          <div className="the">
          The Web3 world is losing its initial spirit and failing to fulfill its promise.
<br/>
We were promised a new world of equal values and justice; a new system of distribution without monopolies. We were promised freedom -- mentally and physically -- for we were the pioneers.
Time to be rebels and break the shackles of the unfair order.           </div>
        </div>   
        <div className="here scratchy">here we come </div>      
         

        <div className="red-text animate__animated animate__slideInLeft">We are rebels</div>
      </div>
    </div>
  );
};

const TwoPage = () => {
  return    <>
   <div className="item-two wap">
  <img className="logo-icon-small" src={logo_s} />

  <div className="flex box">
    <div className="left">
      <div className="the">
        <div className="dou animate__animated animate__slideInDown">“</div>
        <div className="dou-tex"> The economic model is <br/>the Force of RebelsDAO </div>
      </div>
      <div className="it scratchy">It will lead our road.May The Force be with you.</div>
    </div>
    <div className="grid-box wap flex">
      <div className="right one">
        <div className="grid-border">
          <div className="grid">
            User transact $Rebel and 0.7% tax is taken on every TX
          </div>
        </div>

        <div className="grid-border">
          <div className="grid">
            Taxes are collected into a DAO Treasury
          </div>
        </div>

        <div className="grid-border">
          <div className="grid">
            DAO Treasury is managed by a multi-signed wallet and the
            multi-signed team is made by Knights and founders
          </div>
        </div>

        <div className="grid-border">
          <div className="grid">
            The funds in DAO Treasury will be used to invest the projects
            submited by the Knights and agreed by the rebels
          </div>
        </div>
      </div>
      <div className="grid-middle"></div>
      <div className="right two">
        <div className="grid-border">
          <div className="grid">
            Hodlers can stake their $Rebel to get $dRebel and rewards
          </div>
        </div>
       

        <div className="grid-border">
          <div className="grid">
            Top 10 $dRebel hodlers are "Knights" who can participate in the
            multi-signed wallet management and submit protocols for
            investment
          </div>
        </div>

        <div className="grid-border">
          <div className="grid">
            The rest $dRebel hodlers are rebels and can vote for the
            protocols
          </div>
        </div>
        <div className="grid-border end">
          <div className="grid">
            The earnings from the investment will be used to buy our $Rebel
            to increase the token price
          </div>
      
        </div>
      </div>
      <div className="grid-arrow-left">
      </div>
    </div>
  </div>
</div>
  <div className="item-two pc">
  <img className="logo-icon-small" src={logo_s} />

  <div className="flex box">
    <div className="left">
      <div className="the">
        <div className="dou animate__animated animate__slideInDown">“</div>
        <div className="dou-tex"> The economic model is the Force of RebelsDAO.</div>
      </div>
      <div className="it scratchy">It will lead our road.May The Force be with you.</div>
    </div>
    <div className="grid-box pc flex">
      <div className="right one">
        <div className="grid-border">
          <div className="grid">
            User transact $Rebel and 0.7% tax is taken on every TX
          </div>
        </div>

        <div className="grid-arrow"></div>
        <div className="grid-border">
          <div className="grid">
            Taxes are collected into a DAO Treasury
          </div>
        </div>
        <div className="grid-arrow"></div>

        <div className="grid-border">
          <div className="grid">
            DAO Treasury is managed by a multi-signed wallet and the
            multi-signed team is made by Knights and founders
          </div>
        </div>
        <div className="grid-arrow"></div>

        <div className="grid-border">
          <div className="grid">
            The funds in DAO Treasury will be used to invest the projects
            submited by the Knights and agreed by the rebels
          </div>
        </div>
      </div>

      <div className="right two">
        <div className="grid-border">
          <div className="grid">
            Hodlers can stake their $Rebel to get $dRebel and rewards
          </div>
        </div>
        <div className="grid-arrow"></div>

        <div className="grid-border">
          <div className="grid">
            Top 10 $dRebel hodlers are "Knights" who can participate in the
            multi-signed wallet management and submit protocols for
            investment
          </div>
        </div>
        <div className="grid-arrow"></div>

        <div className="grid-border">
          <div className="grid">
            The rest $dRebel hodlers are rebels and can vote for the
            protocols
          </div>
        </div>
        <div className="grid-border end">
          <div className="grid">
            The earnings from the investment will be used to buy our $Rebel
            to increase the token price
          </div>
          <div className="grid-arrow-spec">
            <img src={specArrow} />
          </div>
        </div>
      </div>
      <div className="grid-arrow-left">
        <img src={longArrow} />
      </div>
    </div>
  </div>
</div>
</>

};

const ThreePage = () => {
  return (
    <div className="item-three">
      <img className="logo-icon-small" src={logo_s} />

      <img className="star-bg" src={star} />
      <div className="text-box">
        <SmokeText str="In Star Wars, Jedi Knights offer lots of help to the rebels"/>
        <SmokeText />
      </div>
    </div>
  );
};

const FourPage = () => {
  return (
    <div className="item-four">
      <img className="logo-icon-small" src={logo_s} />
      <div className="wrap">
        <div className="content">
            <div className="t wap scratchy">We are all </div> 
            <div className="t wap scratchy">rebels</div>
            <div className="t pc scratchy">We are all rebels</div>
          <div className="text-box">
            <div className="c">
              <div>
                We are all rebels, rebels against centralized institutions,
                rebels who wanna control our own fate. <br/> 
                The RebelsDAO give you the chance. Grab it, grab it tightly, grab it toughly.<br/> 
                Repeat to me :<br/>
              </div>
               <div className="b pc animated">Be rebel, we are all rebels.<span style={{backgroundColor:'#D9052D',opacity: 0.44}}></span></div>
            </div>
          </div>
        </div>
        <div className="b wap animated">Be rebel, we are all rebels.<span style={{backgroundColor:'#D9052D',opacity: 0.44}}></span></div>
      </div>
      <div className="bg-p">
      <div className="under"></div>
        <div className="person">
              <img src={p2} className="c" />
              <img src={p1}  className='l'/>
              <img src={p3}  className='r'/>
            </div>
        
      </div>
    </div>
  );
};

// 球

export default IntroPage;
