import { useEffect, useRef, useState } from "react";
import "./App.scss";
import "./css/wap.scss";
import Index from "./pages/index/index";
import IntroPage from "./pages/introPage/index";
import HomePage from "./pages/homePage/index";
import ReactAudioPlayer from "react-audio-player";
import sounds from "./voice/The-Cranberries-Zombie.mp3";
import classNames from "classnames";

function App() {
  const [homeShow, setHomeShow] = useState(false);
  const [introShow, setIntroShow] = useState(0);
  const audioRef = useRef(null);
  useEffect(() => {
    audioRef.current.audioEl.current.play();
  }, []);


  return (
    <div
      className={classNames("App", window.document.documentElement.clientWidth <= 750 ? 'mobile' : 'pc')}
    >
        <ReactAudioPlayer src={sounds} autoPlay={false} ref={audioRef} />
      {/* 开屏页面 */}
      {!homeShow && !introShow && (
        <Index
          router={() => {
            setHomeShow(true);
          }}
          audioRef={audioRef}
        />
      )}
      {/* 星球页面 */}
      {homeShow && <HomePage  toIntro={(pageIndex)=>{
        console.log('pageIndex',pageIndex)
        setHomeShow(false)
        setIntroShow(pageIndex)
      }}  audioRef={audioRef} />}

      {/* 详情页面 */}
      {!homeShow && introShow!==0 && <IntroPage pageIndex={introShow} toHome={()=>{ setHomeShow(true) }} audioRef={audioRef}/>}
    </div>
  );
}

export default App;


