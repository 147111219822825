import { useEffect, useRef, useState } from "react";
import roudImg3 from "../../images/home_round3.gif";
import LogoAnimation from "../../components/LogoAnimation/index"; // logo-btn
import "./style.scss";
const Index = ({router,audioRef}) => {
  const [showRoud, setShowRound] = useState(true);
  let timeid = useRef('');
  useEffect(() => {
   
    timeid.current = setTimeout(() => {
      setShowRound(false);
    }, 3000);
    return () => {
      clearTimeout(timeid.current);
    };
  }, []);



  return (
    <div className="home">
      {showRoud ? <LoadingCircle /> : <LogoAnimation router={router}/> }
    </div>
  );
};

// loading圈
const LoadingCircle = () => {
  return (
    <div className="round-box">
      <img className="round-img" src={roudImg3} />
    </div>
  );
};

export default Index;
