import { useEffect, useState } from "react";
import classNames from 'classnames'
import "./style.scss";

// 烟雾文字
const SmokeText = ({ str }) => {
  const [newHtmlStr, setNewHtmlStr] = useState("");
  useEffect(() => {
    let one = `In RebelsDAO, there are 10 Jedi Knights helping and protecting us.
        They jointly manage the RebelsDAO’s Treasury through multisig wallet.
        Also, they can submit investment protocols for the rebels to vote. The
        earnings of the investment will be used to buy $Rebel and reward for
        rebels. Top 10 $dRebel holder will become Knights. So actually,
        Knights are `;
    let two = "immortal";
    let three = ` . They will help and protect the `;
    let four = "rebels";
    let five = ` till never.`;
    const u = navigator.userAgent;
    const is_mobile = window.document.documentElement.clientWidth <= 750

    if (is_mobile) {
      str && setNewHtmlStr(str);
      !str &&
        setNewHtmlStr(
          `${one}<span class='red'>${two}</span>${three}<span class='red'>${four}</span>${five}`
        );
      return;
    }
    if (!str) {
      one = one.replace(/\S/g, "<span>$&</span>");
      two = two.replace(/\S/g, "<div class='red'><span>$&</span></div>");
      three = three.replace(/\S/g, "<span>$&</span>");
      four = four.replace(/\S/g, "<div class='red'><span>$&</span></div>");
      five = five.replace(/\S/g, "<span>$&</span>");
      let longstr = one + two + three + four + five;
      setNewHtmlStr(longstr);
      return;
    }
    str && setNewHtmlStr(str.replace(/\S/g, "<span>$&</span>"));
  }, []);
  return (
  
    <>
      <div className={classNames('main-group',{'title': !!str})}>
        <p
          className="text"
          dangerouslySetInnerHTML={{ __html: newHtmlStr }}
          onMouseOver={(e) => {
            if (e.target.tagName === "SPAN") e.target.className = "action";
          }}
        ></p>
      </div>
    </>
  );
};

export default SmokeText;
