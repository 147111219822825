import React, { useEffect, useState } from "react";

import text1 from "../../images/logo/r.png";
import text2 from "../../images/logo/e1.png";
import text3 from "../../images/logo/b.png";
import text4 from "../../images/logo/e.png";
import text5 from "../../images/logo/l.png";
import text6 from "../../images/logo/s.png";
import qi from "../../images/qi_trans.png";
import logo_trans from "../../images/logo_trans.png";
import "./style.scss";
import classNames from "classnames";
// import {TransitionGroup} from 'react-transition-group'
const NewTextAnimation = ({ router }) => {
  const [show, setshow] = useState(false);
  const ismobile = window.document.documentElement.clientWidth <= 750
  useEffect(() => {
    setTimeout(() => {
      setshow(true);
    }, 2000);
  }, []);
  if(ismobile){
    return   <div className="completed-view flex-column flex-center">
    <ComplatedLogo />
    <HomeText router={router} />
  </div>
  }
  return (
    <>
      {!show ? (
        <LogoAnimate />
      ) : (
        <div className="completed-view flex-column flex-center">
          <ComplatedLogo />
          <HomeText router={router} />
        </div>
      )}
    </>
  );
};

const HomeText = ({ router }) => {
  return (
    <>
    {/* pc */}
     <div className="flex-column flex-center home-text pc">
      <div className="gray-text">
        Through passion, I gain strength. Through strength, I gain power.
      </div>
      <div className="flex">
        <span className="gray-text">
          Through power, I gain victory. Through victory,
        </span>
        <span className="red-text">my chains are broken</span>
      </div>
      <div className="bold-white-text scratchy">
        Hold you ground! Hold rebels ground!
      </div>
      <div
        className="btn  box-link"
        onClick={() => {
          router();
        }}
      >
        <span>BE REBELS</span>
      </div>
    </div>
    {/* wap */}
     <div className="home-text wap">
     <div className="gray-text">Through passion, I gain strength.</div>
     <div className="gray-text">Through strength, I gain power.</div>
     <div className="gray-text">
       Through power, I gain victory. Through victory, <span className="red-text">my chains are broken</span>
     </div>
     <div className="bold-white-text scratchy">
     <div >
       Hold you ground! 
     </div>
     <div >Hold rebels ground!</div>
     </div>

     <div
       className="btn  box-link"
       onClick={() => {
         router();
       }}
     >
       <span>BE REBELS</span>
     </div>
   </div>
    </>
   
  );
};


const LogoAnimate = () => {
  const arr = [text1, text2, text3, text4, text5, text6];
  return (
    <div className="logo-text-animation">
      {arr.map((item, index) => {
        return (
          <img className={classNames("img-roate")} src={item} key={index} />
        );
      })}
      <img className="qi-img" alt="" src={qi} />
    </div>
  );
};

const ComplatedLogo = () => {
  return (
    <div className="completed-box">
      <img src={logo_trans} className="logo"></img>
    </div>
  );
};

export default NewTextAnimation;
